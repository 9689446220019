import React from "react";
import "../styles/Invoicing.css";
import Navbar from "./NavBar";
import Footer from "./Footer";

const Invoicing = () => {
  return (
    <div>
      <Navbar />
      <div className="service-container">
        <div className="service-header">
          <h1>Invoicing</h1>
          <p>
            We manage the invoicing process for you, ensuring accuracy and timely
            submissions so you get paid promptly for your services.
          </p>
        </div>
        <div className="service-description">
          <p>
            Our invoicing service is designed to handle all aspects of your
            invoicing needs. From generating invoices to ensuring timely
            submissions and payments, we take care of everything so you can focus
            on your core business activities.
          </p>
          <button
            className="signup-button"
            onClick={() => (window.location.href = "/carrier-setup")}
          >
            SIGN UP TODAY!
          </button>
        </div>

        <div className="service-details">
          <h2>Our Invoicing Process</h2>
          <p>
            Our streamlined invoicing process ensures that you get paid promptly
            and accurately for every job.
          </p>
          <div className="process-flow">
            <div className="process-step">
              <div className="step-number">1</div>
              <div className="step-description">
                <h3>Generate Invoices</h3>
                <p>
                  We create detailed invoices based on your completed jobs,
                  ensuring all necessary information is included.
                </p>
              </div>
            </div>
            <div className="process-step">
              <div className="step-number">2</div>
              <div className="step-description">
                <h3>Submit Invoices</h3>
                <p>
                  Invoices are submitted promptly to your clients to ensure timely
                  payments.
                </p>
              </div>
            </div>
            <div className="process-step">
              <div className="step-number">3</div>
              <div className="step-description">
                <h3>Track Payments</h3>
                <p>
                  We monitor the payment process, following up with clients as
                  necessary to ensure you get paid on time.
                </p>
              </div>
            </div>
            <div className="process-step">
              <div className="step-number">4</div>
              <div className="step-description">
                <h3>Record Keeping</h3>
                <p>
                  All invoicing data is meticulously recorded and stored for easy
                  reference and auditing.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="benefits-section">
          <h2>Benefits of Our Service</h2>
          <ul>
            <li>
              <span>✔</span> Accurate and timely invoicing
            </li>
            <li>
              <span>✔</span> Reduced administrative burden
            </li>
            <li>
              <span>✔</span> Enhanced cash flow management
            </li>
            <li>
              <span>✔</span> Professional invoice generation
            </li>
            <li>
              <span>✔</span> Comprehensive record-keeping
            </li>
            <li>
              <span>✔</span> 24/7 support from experienced staff
            </li>
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Invoicing;
