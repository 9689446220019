import React from "react";
import { Link } from "react-router-dom";
import "../styles/ServicesOffered.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const serviceData = [
  {
    title: "Rate Negotiation",
    description:
      "Expertise in negotiating freight charges with brokers to ensure competitive pricing and favorable terms.",
    bgColor: "#FFC0CB",
    link: "/negotiate-rates", // Add the link for this service
  },
  {
    title: "Logistics Management",
    description:
      "Coordinating load assignments from start to finish, including paperwork and factoring services. Drivers focus only on driving.",
    bgColor: "#90EE90",
    link: "/book-your-load", // Add the link for this service
  },
  {
    title: "Support Services",
    description:
      "Offering 24/7 support, including route optimization, fuel management, and operational advice for smooth transit.",
    bgColor: "#FFA07A",
    link: "/personal-dispatcher", // Add the link for this service
  },
];

const ServiceCard = ({ title, description, bgColor, link }) => (
  <div className="service-card" style={{ backgroundColor: bgColor }}>
    <h3>{title}</h3>
    <p>{description}</p>
    <Link
      to={link}
      className="learn-more"
      onClick={() => window.scrollTo(0, 0)}
    >
      Learn More <FontAwesomeIcon icon={faArrowRight} />
    </Link>
  </div>
);

const ServicesOffered = () => {
  return (
    <div className="services-offered">
      <h2>Services Offered</h2>
      <div className="services-grid">
        {serviceData.map((service, index) => (
          <ServiceCard key={index} {...service} />
        ))}
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", padding: "20px" }}
      >
        <button
          className="cta-button"
          onClick={() => (window.location.href = "/carrier-setup")}
        >
          Drive with Us
        </button>
      </div>
    </div>
  );
};

export default ServicesOffered;
