import React from "react";
import "../styles/BookYourLoad.css";
import Navbar from "./NavBar";
import Footer from "./Footer";

const BookYourLoad = () => {
  return (
    <div>
      <Navbar />
      <div className="service-container">
        <div className="service-header">
          <h1>Low Board</h1>
        </div>
        <div className="service-description"></div>

        <div className="service-details">
          <h2>How It Works</h2>
          <p>
            Our process is designed to be straightforward and efficient, saving
            you time and effort while maximizing your earnings.
          </p>
          <div className="service-cards">
            <div className="service-card">
              <h3>1. Understanding Your Preferences</h3>
              <p>
                We start by understanding your specific requirements,
                preferences, and constraints. Do you prefer to be home on
                weekends? Do you avoid certain routes? We take all your needs
                into account.
              </p>
            </div>
            <div className="service-card">
              <h3>2. Finding the Best Loads</h3>
              <p>
                Our dispatchers use advanced tools and a vast network of
                contacts to find the best loads that match your criteria. We
                ensure that you get the highest paying loads and the best
                routes.
              </p>
            </div>
            <div className="service-card">
              <h3>3. Negotiating Rates</h3>
              <p>
                We handle all the negotiations to get you the best possible
                rates. Our goal is to maximize your earnings on every load.
              </p>
            </div>
            <div className="service-card">
              <h3>4. Managing Documentation</h3>
              <p>
                From rate confirmations to broker packets, we handle all the
                necessary documentation. This allows you to focus on driving
                while we take care of the paperwork.
              </p>
            </div>
            <div className="service-card">
              <h3>5. Providing Support</h3>
              <p>
                Our support doesn't end once the load is booked. We are
                available 24/7 to address any issues or changes that may arise
                during your haul.
              </p>
            </div>
          </div>
          <button
            className="signup-button"
            onClick={() => (window.location.href = "/carrier-setup")}
          >
            SIGN UP TODAY!
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default BookYourLoad;
