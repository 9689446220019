import React from "react";
import "../styles/NegotiateTheBestRates.css";
import Navbar from "./NavBar";
import Footer from "./Footer";

const NegotiateTheBestRates = () => {
  return (
    <div>
      <Navbar />
      <div className="service-container">
        <div className="service-header">
          <h1>Negotiate the Best Rates</h1>
        </div>
        <div className="service-description"></div>

        <div className="service-details">
          <h2>Why Choose Us?</h2>
          <p>
            Our approach to rate negotiation is designed to provide you with the
            highest possible returns on your hauls. Here's how we do it:
          </p>
          <div className="service-cards">
            <div className="service-card">
              <h3>Industry Expertise</h3>
              <p>
                Our dispatchers have extensive knowledge of the freight
                industry, allowing them to identify the most profitable
                opportunities for you.
              </p>
            </div>
            <div className="service-card">
              <h3>Strong Network</h3>
              <p>
                We have built strong relationships with brokers and shippers,
                giving us access to premium loads that others may not have.
              </p>
            </div>
            <div className="service-card">
              <h3>Data-Driven Decisions</h3>
              <p>
                Using the latest market data and trends, we make informed
                decisions to secure the best rates for your loads.
              </p>
            </div>
            <div className="service-card">
              <h3>Continuous Improvement</h3>
              <p>
                We constantly refine our negotiation strategies to adapt to
                market changes and improve your earnings over time.
              </p>
            </div>
            <div className="service-card">
              <h3>Personalized Service</h3>
              <p>
                We tailor our negotiation approach to fit your specific needs
                and preferences, ensuring that you get the best possible
                outcomes.
              </p>
            </div>
          </div>
          <button
            className="signup-button"
            onClick={() => (window.location.href = "/carrier-setup")}
          >
            SIGN UP TODAY!
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default NegotiateTheBestRates;
