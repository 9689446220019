import React from "react";
import "../styles/PersonalDispatcher.css";
import Navbar from "./NavBar";
import Footer from "./Footer";

const PersonalDispatcher = () => {
  return (
    <div>
      <Navbar />
      <div className="service-container">
        <div className="service-header">
          <h1>Personal Dispatcher</h1>
        </div>
        <div className="service-description"></div>

        <div className="pricing-plans">
          <h2>No Contract Pricing Plans</h2>
          <p>
            We understand that finding a great OTR dispatcher is not an easy
            task. We offer NO CONTRACT pricing because we are 100% confident
            that you are going to love our services. You are more than just a
            driver, you are family!
          </p>
          <div className="pricing-cards">
            <div className="pricing-card">
              <h3>DISPATCHING</h3>
              <p>%8/ per week (per truck)</p>
              <ul>
                <li>Personal Dispatcher</li>
                <li>Negotiate high paying loads</li>
                <li>Back Office Support</li>
                <li>24/7 Support (Drivers Only)</li>
                <li>No Forced Dispatching</li>
                <li>You set your schedule</li>
                <li>New Authorities are Welcome!</li>
              </ul>
              <button
                className="learn-more-button"
                onClick={() => (window.location.href = "/carrier-setup")}
              >
                LEARN MORE
              </button>
            </div>
            <div className="pricing-card">
              <h3>FACTOR INVOICING</h3>
              <p>$75/ week (max 4 trucks)</p>
              <ul>
                <li>Paid in 1-2 business days</li>
                <li>Invoice creation</li>
                <li>Send in all paperwork</li>
                <li>Track Payments</li>
                <li>24/7 Support</li>
                <li>We do all the work</li>
                <li>We invoice all factoring companies!</li>
              </ul>
              <button
                className="learn-more-button"
                onClick={() => (window.location.href = "/carrier-setup")}
              >
                LEARN MORE
              </button>{" "}
            </div>
            <div className="pricing-card">
              <h3>BROKER PACKETS</h3>
              <p>$75 / per week (max 4 trucks)</p>
              <ul>
                <li>You book your own loads</li>
                <li>All Carriers Accepted</li>
                <li>Completion of all broker packets</li>
                <li>
                  Complete <a href="/carrier-setup">Carrier Set-Up</a>
                </li>
                <li>24/7 Support</li>
                <li>Consultation Available</li>
                <li>Networking</li>
                <li>No dispatching required for this service!</li>
              </ul>
              <button
                className="learn-more-button"
                onClick={() => (window.location.href = "/carrier-setup")}
              >
                LEARN MORE
              </button>{" "}
            </div>
          </div>
          <button
            className="signup-button"
            onClick={() => (window.location.href = "/carrier-setup")}
          >
            SIGN UP TODAY!
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PersonalDispatcher;
