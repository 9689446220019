import React from "react";
import "../styles/HeroSection.css";
import "../styles/CommonComponents.css";
import handshakeImage from "../assets/handshake.jpeg"; // Single image to be used

const HeroSection = () => {
  return (
    <div
      className="hero-section"
      style={{ backgroundImage: `url(${handshakeImage})` }}
    >
      <div className="hero-overlay">
        <h1>Trust, Transparency, and Safety</h1>
        <p>
          At Al Amanah, we're more than just a business - we're a family of
          dispatchers driven by a passion for booking quality loads, and making
          our drivers' life easier. Founded in 2023, even though we're fairly
          new, we've been on a mission to bring a different approach to the
          trucking business. We want to be as transparent as possible, make our
          partners money, build relationships, and shake the industry up.
        </p>
        <div className="cta-buttons">
          <button
            className="cta-button"
            onClick={() => (window.location.href = "/carrier-setup")}
          >
            Drive with Us
          </button>
          <button
            className="cta-button"
            onClick={() => (window.location.href = "/delivery-setup")}
          >
            Brokers
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
