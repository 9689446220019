import React from "react";
import { useForm } from "react-hook-form";
import "../styles/CarrierSetup.css";
import { useState, useEffect } from "react";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";

const CarrierSetup = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const selectedStates = watch("statesPreferred") || [];
  const onSubmit = (data) => console.log(data);

  const states = [
    "No preference",
    "Alabama",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  useEffect(() => {
    // Set default selected states on initial load
    setValue("statesPreferred", []);
  }, [setValue]);

  const handleStateChange = (state) => {
    let newSelectedStates = [];
    if (state === "No preference") {
      newSelectedStates = selectedStates.includes(state)
        ? []
        : ["No preference"];
    } else {
      if (selectedStates.includes("No preference")) {
        newSelectedStates = [state];
      } else {
        newSelectedStates = selectedStates.includes(state)
          ? selectedStates.filter((s) => s !== state)
          : [...selectedStates, state];
      }
    }
    setValue("statesPreferred", newSelectedStates); // This will not cause re-render immediately
  };

  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (event) => {
    setSelectedFiles([...event.target.files]); // Assuming no server upload for simplicity
  };

  const removeFile = (fileName) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName)
    );
  };

  return (
    <div>
      <Navbar />
      <div className="carrier-setup">
        <h1 className="form-title">Carrier Set-Up</h1>
        <p className="form-description">
          Once you complete the carrier setup below, we will email you a
          Dispatch Service Agreement along with a General Power Of Attorney
          (Agent) and you’ll all be set. Don’t forget to attach your MC
          Authority, W9 & Insurance below!
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="companyName">
              Company Name<span className="required">*</span>
            </label>
            <input
              id="companyName"
              {...register("companyName", {
                required: "Company Name is required",
              })}
              placeholder="Enter company name"
              className="form-field"
            />
            {errors.companyName && (
              <p className="error">{errors.companyName.message}</p>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="dba">DBA (if any)</label>
            <input
              id="dba"
              {...register("dba")}
              placeholder="DBA"
              className="form-field"
            />
          </div>
          <div className="address-group">
            <div className="form-group">
              <label htmlFor="address">
                Address<span className="required">*</span>
              </label>
              <input
                id="address"
                {...register("address", { required: "Address is required" })}
                placeholder="Enter address"
                className="form-field"
              />
              {errors.address && (
                <p className="error">{errors.address.message}</p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="city">City</label>
              <input
                id="city"
                {...register("city")}
                placeholder="City"
                className="form-field"
              />
            </div>
            <div className="form-group">
              <label htmlFor="state">State</label>
              <select
                id="state"
                {...register("state")}
                placeholder="State"
                className="form-field"
              >
                {states.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="zip">ZIP Code</label>
              <input
                id="zip"
                {...register("zip")}
                placeholder="ZIP Code"
                className="form-field"
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="phone">
              Phone<span className="required">*</span>
            </label>
            <input
              type="tel"
              id="phone"
              {...register("phone", { required: "Phone is required" })}
              placeholder="Phone number"
              className="form-field"
            />
            {errors.phone && <p className="error">{errors.phone.message}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="email">
              Email<span className="required">*</span>
            </label>
            <input
              type="email"
              id="email"
              {...register("email", { required: "Email is required" })}
              placeholder="Email"
              className="form-field"
            />
            {errors.email && <p className="error">{errors.email.message}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="mcNumber">
              MC Number<span className="required">*</span>
            </label>
            <input
              id="mcNumber"
              {...register("mcNumber", { required: "MC Number is required" })}
              placeholder="MC Number"
              className="form-field"
            />
            {errors.mcNumber && (
              <p className="error">{errors.mcNumber.message}</p>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="usdotNumber">
              USDOT Number<span className="required">*</span>
            </label>
            <input
              id="usdotNumber"
              {...register("usdotNumber", {
                required: "USDOT Number is required",
              })}
              placeholder="USDOT Number"
              className="form-field"
            />
            {errors.usdotNumber && (
              <p className="error">{errors.usdotNumber.message}</p>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="feinSsn">
              FEIN/SSN<span className="required">*</span>
            </label>
            <input
              id="feinSsn"
              {...register("feinSsn", { required: "FEIN/SSN is required" })}
              placeholder="FEIN or SSN"
              className="form-field"
            />
            {errors.feinSsn && (
              <p className="error">{errors.feinSsn.message}</p>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="typeOfEquipment">Select Equipment Type</label>
            <select
              id="typeOfEquipment"
              {...register("typeOfEquipment")}
              className="form-select"
            >
              <option value="Dry Van">Dry Van</option>
              <option value="Reefer">Reefer</option>
              <option value="Flatbed">Flatbed</option>
              <option value="Conestoga">Conestoga</option>
            </select>
          </div>
          <div className="radio-buttons">
            <p>Do you factor invoices?</p>
            <label>
              <input
                {...register("doYouFactorInvoices")}
                type="radio"
                value="Yes"
              />{" "}
              Yes
            </label>
            <label>
              <input
                {...register("doYouFactorInvoices")}
                type="radio"
                value="No"
              />{" "}
              No
            </label>
          </div>
          <div className="form-group">
            <label>Preferred States for Driving</label>
            <div className="checkbox-group">
              {states.map((state) => (
                <label key={state}>
                  <input
                    type="checkbox"
                    value={state}
                    onChange={() => handleStateChange(state)}
                    checked={selectedStates.includes(state)}
                  />{" "}
                  {state}
                </label>
              ))}
            </div>
          </div>
          <div className="form-group file-upload">
            <label>Upload Documents</label>
            <div className="file-drop-area">
              <button type="button" className="fake-btn">
                Select files
              </button>
              <span className="file-msg">or drop files here</span>
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                className="file-input"
              />
            </div>
            {selectedFiles.map((file, index) => (
              <div key={index} className="file-info">
                <span>{file.name}</span>
                <button type="button" onClick={() => removeFile(file.name)}>
                  Remove
                </button>
              </div>
            ))}
          </div>
          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default CarrierSetup;
