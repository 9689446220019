import React from "react";
import "../styles/GettingStarted.css";
import {
  FaPencilAlt,
  FaRegFileAlt,
  FaTruck,
  FaFileSignature,
  FaFileInvoiceDollar,
  FaAddressCard,
} from "react-icons/fa";

const GettingStarted = () => {
  return (
    <div className="getting-started-container">
      <h2 className="getting-started-header">
        Getting Started With Al Amanah Transport
      </h2>
      <div className="getting-started-section">
        <div className="getting-started-card">
          <FaPencilAlt className="getting-started-icon" />
          <div className="getting-started-title">Sign Agreement</div>
          <p>Sign a quick service agreement</p>
        </div>
        <div className="getting-started-card">
          <FaRegFileAlt className="getting-started-icon" />
          <div className="getting-started-title">Send Us Your Docs</div>
          <p>MC Authority, W-9, and Insurance</p>
        </div>
        <div className="getting-started-card">
          <FaTruck className="getting-started-icon" />
          <div className="getting-started-title">Start Hauling Loads</div>
          <p>We begin dispatching immediately</p>
        </div>
      </div>
      <p className="getting-started-intro">
        Setting up our Truck Dispatch Services is straightforward. When you’re
        ready to collaborate with us, we'll send you a link to complete a
        carrier application. After we receive all the necessary documents,
        you’ll be assigned a personal dispatcher and can begin working
        immediately. Our company is known for its reliability and excellent
        relationships with carriers. This is why our onboarding process is
        seamless and efficient. Here’s what you need to get started:
      </p>

      <ul className="getting-started-list">
        <li>
          <FaFileSignature className="list-icon" /> Signed Truck Dispatch
          Service Agreement
        </li>
        <li>
          <FaFileInvoiceDollar className="list-icon" /> Factoring Information
          (if any)
        </li>
        <li>
          <FaRegFileAlt className="list-icon" /> General Power Of Attorney
          (allows us to contact brokers on your behalf)
        </li>
        <li>
          <FaTruck className="list-icon" /> Copy Of Your MC Authority Letter
        </li>
        <li>
          <FaAddressCard className="list-icon" /> Copy Of The Insurance
          Certificate
        </li>
        <li>
          <FaAddressCard className="list-icon" /> Driver’s Contact Info
        </li>
        <li>
          <FaRegFileAlt className="list-icon" /> Copy Of W-9 Form
        </li>
      </ul>

      <div className="cta-buttons">
        <button
          className="cta-button"
          onClick={() => (window.location.href = "/carrier-setup")}
        >
          Drive with Us
        </button>
      </div>
    </div>
  );
};

export default GettingStarted;
