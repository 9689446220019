import React from "react";
import "../styles/Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  const handlePhoneClick = () => {
    window.location.href = "tel:3136991039";
  };

  const handleEmailClick = () => {
    window.open("mailto:alamanahtrans@gmail.com", "_blank");
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <h4>Contact us:</h4>
        <p onClick={handlePhoneClick} style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faPhone} /> 313-699-1039
        </p>
        <p onClick={handleEmailClick} style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faEnvelope} /> alamanahtrans@gmail.com
        </p>
        <div className="footer-icons">
          <a href="tel:3136991039" aria-label="Phone">
            <FontAwesomeIcon icon={faPhone} />
          </a>
          <a href="mailto:alamanahtrans@gmail.com" aria-label="Email">
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
