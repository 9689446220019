import React from "react";
import "../styles/Email.css";
import Navbar from "./NavBar";
import Footer from "./Footer";

const Email = () => {
  return (
    <div>
      <Navbar />
      <div className="service-container">
        <div className="service-header">
          <h1>Email</h1>
          <p>
            Our team handles all email communications related to dispatching,
            including negotiations, confirmations, and follow-ups, ensuring
            nothing is missed.
          </p>
        </div>
        <div className="service-description">
          <p>
            Effective email communication is essential for the smooth operation of
            your business. We ensure that all dispatch-related emails are
            professionally managed, keeping you informed and in control at all
            times.
          </p>
          <button
            className="signup-button"
            onClick={() => (window.location.href = "/carrier-setup")}
          >
            SIGN UP TODAY!
          </button>
        </div>

        <div className="service-details">
          <h2>Our Email Management Services</h2>
          <p>
            We offer a comprehensive email management service that covers all
            aspects of your dispatch needs.
          </p>
          <div className="email-services">
            <div className="email-service">
              <h3>Negotiations</h3>
              <p>
                We handle all email negotiations with brokers and clients to secure
                the best deals for you.
              </p>
            </div>
            <div className="email-service">
              <h3>Confirmations</h3>
              <p>
                Our team manages email confirmations to ensure all details are
                correct and agreed upon before proceeding.
              </p>
            </div>
            <div className="email-service">
              <h3>Follow-Ups</h3>
              <p>
                We conduct follow-up emails to keep all parties informed and ensure
                smooth operations.
              </p>
            </div>
            <div className="email-service">
              <h3>Record Keeping</h3>
              <p>
                All email communications are meticulously recorded for future
                reference and accountability.
              </p>
            </div>
          </div>
        </div>

        <div className="benefits-section">
          <h2>Benefits of Our Service</h2>
          <ul>
            <li>Professional and timely email management</li>
            <li>Accurate record-keeping of all communications</li>
            <li>Enhanced negotiation capabilities</li>
            <li>Reduced administrative burden</li>
            <li>Improved communication efficiency</li>
            <li>24/7 support from experienced staff</li>
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Email;
