import React, { useState } from "react";
import { FaCaretDown } from "react-icons/fa";
import "../styles/NavBar.css";
import logoImage from "../assets/logo.png"; // Import the logo image

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = (e) => {
    e.preventDefault();
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav className="navbar">
      <img src={logoImage} alt="Al Amanah Transports" className="logo" />
      <div className={`nav-links ${isOpen ? "open" : ""}`}>
        <a href="/" className="nav-item">
          Home
        </a>
        <div className="nav-item dropdown">
          <a
            href="/services"
            className="dropdown-toggle"
            onClick={toggleDropdown}
          >
            Services <FaCaretDown className="dropdown-icon" />
          </a>
          <div className={`dropdown-content ${isDropdownOpen ? "show" : ""}`}>
            <a href="/personal-dispatcher" className="dropdown-item">
              Personal Dispatcher
            </a>
            <a href="/book-your-load" className="dropdown-item">
              Low Board
            </a>
            <a href="/run-credit-checks" className="dropdown-item">
              Credit Checks
            </a>
            <a href="/back-office-support" className="dropdown-item">
              Back Office Support
            </a>
            <a href="/communication" className="dropdown-item">
              Communication
            </a>
            <a href="/invoicing" className="dropdown-item">
              Invoicing
            </a>
            <a href="/networking" className="dropdown-item">
              Networking
            </a>
          </div>
        </div>
        <a href="/about-us" className="nav-item">
          About Us
        </a>
        <a href="/delivery-setup" className="nav-item nav-cta">
          New Broker Form
        </a>
        <a href="/carrier-setup" className="nav-item nav-cta">
          New Carrier Form
        </a>
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        <div className={`menu-icon-line ${isOpen ? "open" : ""}`}></div>
        <div className={`menu-icon-line ${isOpen ? "open" : ""}`}></div>
        <div className={`menu-icon-line ${isOpen ? "open" : ""}`}></div>
      </div>
    </nav>
  );
};

export default Navbar;
