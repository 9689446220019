import React from "react";
import "../styles/Networking.css";
import Navbar from "./NavBar";
import Footer from "./Footer";

const Networking = () => {
  return (
    <div>
      <Navbar />

      <div className="service-container">
        <div className="service-header">
          <h1>Networking</h1>
        </div>
        <div className="service-description">
          <p>
            Our networking services are designed to help you make the right
            connections and open doors to new opportunities. We utilize our vast
            network to provide you with the best clients, partners, and
            resources needed to grow your business.
          </p>
          <button
            className="signup-button"
            onClick={() => (window.location.href = "/carrier-setup")}
          >
            SIGN UP TODAY!
          </button>
        </div>

        <div className="timeline">
          <div className="timeline-item">
            <div className="timeline-content">
              <h3>1. Initial Consultation</h3>
              <p>
                We begin with an initial consultation to understand your
                business needs and goals.
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3>2. Identify Opportunities</h3>
              <p>
                Using our network, we identify the best opportunities that align
                with your goals.
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3>3. Introductions and Connections</h3>
              <p>
                We introduce you to key contacts and help you build valuable
                relationships.
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3>4. Ongoing Support</h3>
              <p>
                Our support doesn't end with introductions. We provide ongoing
                assistance to ensure successful collaborations.
              </p>
            </div>
          </div>
        </div>

        <div className="benefits-section">
          <h2>Benefits of Our Service</h2>
          <ul>
            <li>
              <span>✔</span> Access to top-tier clients
            </li>
            <li>
              <span>✔</span> Expanded business opportunities
            </li>
            <li>
              <span>✔</span> Professional networking support
            </li>
            <li>
              <span>✔</span> Enhanced industry connections
            </li>
            <li>
              <span>✔</span> Ongoing partnership development
            </li>
            <li>
              <span>✔</span> 24/7 support from experienced staff
            </li>
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Networking;
