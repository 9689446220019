import React from "react";
import "../styles/BackOfficeSupport.css";
import Navbar from "./NavBar";
import Footer from "./Footer";

const BackOfficeSupport = () => {
  return (
    <div>
      <Navbar />
      <div className="service-container">
        <div className="service-header"></div>

        <div className="service-details">
          <h2>Our Back Office Support Services</h2>
          <p>
            Our back office support encompasses a wide range of services
            designed to streamline your operations and ensure everything runs
            smoothly.
          </p>
          <div className="service-cards">
            <div className="service-card">
              <h3>Documentation Management</h3>
              <p>
                We handle all your paperwork, including bills of lading, rate
                confirmations, and broker packets, ensuring everything is in
                order.
              </p>
            </div>
            <div className="service-card">
              <h3>Compliance Assurance</h3>
              <p>
                Our team ensures that all your operations comply with industry
                regulations and standards, keeping you on the right side of the
                law.
              </p>
            </div>
            <div className="service-card">
              <h3>Billing and Invoicing</h3>
              <p>
                We manage the billing and invoicing process, ensuring accuracy
                and timely submissions to keep your cash flow steady.
              </p>
            </div>
            <div className="service-card">
              <h3>Client Communication</h3>
              <p>
                Our team handles all client communications, keeping you informed
                and addressing any issues that arise promptly and
                professionally.
              </p>
            </div>
          </div>
          <button
            className="signup-button"
            onClick={() => (window.location.href = "/carrier-setup")}
          >
            SIGN UP TODAY!
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default BackOfficeSupport;
