import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./components/HomePage";
import AboutUs from "./components/AboutUs";
import Contact from "./components/Contact";
import CarrierSetup from "./pages/CarrierSetup";
import DeliverySetup from "./pages/DeliverySetup";
import Services from "./pages/Services";
import About from "./pages/About";
import PersonalDispatcher from "./components/PersonalDispatcher";
import BookYourLoad from "./components/BookYourLoad";
import NegotiateTheBestRates from "./components/NegotiateTheBestRates";
import RunCreditChecks from "./components/RunCreditChecks";
import BackOfficeSupport from "./components/BackOfficeSupport";
import Communication from "./components/Communication";
import Email from "./components/Email";
import Invoicing from "./components/Invoicing";
import Networking from "./components/Networking";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} exact />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/carrier-setup" element={<CarrierSetup />} />
        <Route path="/delivery-setup" element={<DeliverySetup />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/personal-dispatcher" element={<PersonalDispatcher />} />
        <Route path="/book-your-load" element={<BookYourLoad />} />
        <Route path="/negotiate-rates" element={<NegotiateTheBestRates />} />
        <Route path="/run-credit-checks" element={<RunCreditChecks />} />
        <Route path="/back-office-support" element={<BackOfficeSupport />} />
        <Route path="/communication" element={<Communication />} />
        <Route path="/email" element={<Email />} />
        <Route path="/invoicing" element={<Invoicing />} />
        <Route path="/networking" element={<Networking />} />
        <Route path="*" element={<h1>Page not found</h1>} />
      </Routes>
    </Router>
  );
}

export default App;
