import React, { useState, useEffect } from "react";
import "../styles/ChooseUs.css";
import customerSatisfaction from "../assets/customer-satisfaction.png";
import community from "../assets/customer-satisfaction2.png";
import quality from "../assets/quality.png";
import innovation from "../assets/innovation.png";
import reliability from "../assets/reliability-2.png";

const tabsData = [
  {
    id: 0,
    title: "Motor Carrier Compliance",
    content:
      "Our dispatchers ensure you meet all motor carrier compliance standards, helping you avoid legal issues and maintain a steady income.",
    imageUrl: quality,
  },
  {
    id: 1,
    title: "Customer Care",
    content:
      "We assist in managing relationships with shippers, setting service expectations with brokers, and resolving complex issues that may arise.",
    imageUrl: customerSatisfaction,
  },
  {
    id: 2,
    title: "Managing Delays",
    content:
      "Our dispatch team helps you handle unexpected delays due to weather, traffic, and other road conditions, ensuring a smoother transportation process.",
    imageUrl: innovation,
  },
  {
    id: 3,
    title: "Efficient Load Booking",
    content:
      "Initially, you might book loads yourself, but as your business grows, this becomes time-consuming. Our dispatchers take over this task, allowing you to focus on running your business.",
    imageUrl: reliability,
  },
  {
    id: 4,
    title: "Maximizing Earnings",
    content:
      ": We help you find higher-paying loads, increasing your profitability and ensuring consistent business flow.",
    imageUrl: community,
  },
];

const ChooseUs = () => {
  const [activeTab, setActiveTab] = useState(tabsData[0].id);
  const activeTabData = tabsData.find((tab) => tab.id === activeTab);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTab((prevActiveTab) => {
        const nextTabId = (prevActiveTab + 1) % tabsData.length;
        return tabsData[nextTabId].id;
      });
    }, 15000); // Change tab every 15 seconds

    return () => clearInterval(interval); // Clean up on component unmount
  }, []);

  return (
    <div className="choose-us-container">
      <h1>Why Choose Us?</h1>
      <div className="tabs">
        {tabsData.map((tab) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`tab-button ${activeTab === tab.id ? "active" : ""}`}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="tab-content">
        <img
          src={activeTabData.imageUrl}
          alt={activeTabData.title}
          className="tab-image"
        />
        <div className="content">
          <p>{activeTabData.content}</p>
        </div>
      </div>
      <div className="cta-buttons">
        <button
          className="cta-button"
          onClick={() => (window.location.href = "/delivery-setup")}
        >
          Brokers
        </button>
      </div>
    </div>
  );
};

export default ChooseUs;
