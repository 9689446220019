import React from "react";
import "../styles/Services.css";
import Footer from "../components/Footer";
import Navbar from "../components/NavBar";
import { Link } from "react-router-dom";

const services = [
  {
    title: "Personal Dispatcher",
    description:
      "Our personal dispatchers provide dedicated and personalized support, ensuring that all your unique needs and preferences are met efficiently and effectively.",
    icon: "fas fa-user-tie",
    link: "/personal-dispatcher",
  },
  {
    title: "Book Your Load",
    description:
      "We handle the entire process of booking your loads, ensuring you get the best options that meet your specific requirements and deadlines.",
    icon: "fas fa-truck-loading",
    link: "/book-your-load",
  },
  {
    title: "Negotiate the Best Rates",
    description:
      "Our experienced dispatchers are skilled negotiators who work tirelessly to secure the highest paying rates for your loads, maximizing your earnings.",
    icon: "fas fa-handshake",
    link: "/negotiate-rates",
  },
  {
    title: "Run Credit Checks",
    description:
      "We conduct thorough credit checks to ensure the reliability and trustworthiness of the clients we book for you, minimizing risks and ensuring timely payments.",
    icon: "fas fa-credit-card",
    link: "/run-credit-checks",
  },
  {
    title: "Back Office Support",
    description:
      "Our comprehensive back office support services take care of all the administrative tasks, allowing you to focus on driving and delivering loads.",
    icon: "fas fa-briefcase",
    link: "/back-office-support",
  },
  {
    title: "Communication",
    description:
      "We maintain open and clear communication with you at all times, ensuring you are always informed and up-to-date on all aspects of your dispatching services.",
    icon: "fas fa-comments",
    link: "/communication",
  },
  {
    title: "Email",
    description:
      "Our team handles all email communications related to dispatching, including negotiations, confirmations, and follow-ups, ensuring nothing is missed.",
    icon: "fas fa-envelope",
    link: "/email",
  },
  {
    title: "Invoicing",
    description:
      "We manage the invoicing process for you, ensuring accuracy and timely submissions so you get paid promptly for your services.",
    icon: "fas fa-file-invoice-dollar",
    link: "/invoicing",
  },
  {
    title: "Networking",
    description:
      "We leverage our extensive network to connect you with the best clients and opportunities, helping you grow your business and succeed in the industry.",
    icon: "fas fa-network-wired",
    link: "/networking",
  },
];

const Services = () => {
  return (
    <div>
      <Navbar />
      <div className="services-section">
        <h1 className="services-header">Our Truck Dispatch Services</h1>
        <p className="services-intro">
          We save you time and make you money with our comprehensive Truck
          Dispatch Services. Let our dedicated and experienced dispatchers
          handle the complexities, so you can focus on what you do best -
          driving!
        </p>
        <div className="services-list">
          {services.map((service, index) => (
            <Link to={service.link} className="service-item" key={index}>
              <i className={`service-icon ${service.icon}`}></i>
              <h2 className="service-title">{service.title}</h2>
              <p className="service-description">{service.description}</p>
            </Link>
          ))}
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", padding: "20px" }}
        >
          <button
            className="cta-button"
            onClick={() => (window.location.href = "/delivery-setup")}
          >
            Brokers
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Services;
