import React from "react";
import "../styles/TruckTypesSection.css";

// Default images (replace with your own images later)
import defaultImage1 from "../assets/ford.png";
import defaultImage2 from "../assets/flatbed.webp.png";
import defaultImage3 from "../assets/reefer2.png";
import defaultImage4 from "../assets/box.webp.png";

const services = [
  {
    image: defaultImage3,
    title: "Reefers",
    description:
      "Our reefer dispatch services ensure your cargo stays cool and fresh.",
  },
  {
    image: defaultImage2,
    title: "Flatbeds",
    description:
      "Need expert flatbed dispatchers? We have the flatbed expertise you require.",
  },
  {
    image: defaultImage4,
    title: "Box Trucks",
    description:
      "Reliable box truck dispatching to get your goods where they need to be.",
  },
  {
    image: defaultImage1,
    title: "Dry Vans",
    description:
      "Our Dry Van dispatchers manage your fleet with precision and care.",
  },
];

const TruckTypeCard = ({ image, title, description }) => (
  <div className="service-card">
    <div className="image-container">
      <img src={image} alt={title} />
    </div>
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

const TruckTypesSection = () => {
  return (
    <section className="services-section">
      <h2>Our Truck Dispatchers Specialize in:</h2>
      <div className="services-grid">
        {services.map((service, index) => (
          <TruckTypeCard key={index} {...service} />
        ))}
      </div>
      <div className="cta-buttons">
        <button
          className="cta-button"
          onClick={() => (window.location.href = "/carrier-setup")}
        >
          Drive with Us
        </button>
      </div>
    </section>
  );
};

export default TruckTypesSection;
