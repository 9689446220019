import React from "react";
import "../styles/Communication.css";
import Navbar from "./NavBar";
import Footer from "./Footer";

const Communication = () => {
  return (
    <div>
      <Navbar />
      <div className="service-container">
        <div className="service-header"></div>
        <div className="service-description"></div>

        <div className="service-details">
          <h2>Key Features of Our Communication Service</h2>
          <p>
            We offer a range of communication features to ensure you are always
            connected and informed.
          </p>
          <div className="communication-features">
            <div className="feature-card">
              <h3>Real-Time Updates</h3>
              <p>
                Receive instant notifications and updates about your loads and
                dispatch status.
              </p>
            </div>
            <div className="feature-card">
              <h3>24/7 Support</h3>
              <p>
                Our support team is available around the clock to assist you
                with any issues or questions.
              </p>
            </div>
            <div className="feature-card">
              <h3>Transparent Information</h3>
              <p>
                We provide clear and detailed information about your loads and
                dispatching process.
              </p>
            </div>
            <div className="feature-card">
              <h3>Multi-Channel Communication</h3>
              <p>
                Stay connected through various communication channels, including
                phone, email, and chat.
              </p>
            </div>
            <button
              className="signup-button"
              onClick={() => (window.location.href = "/carrier-setup")}
            >
              SIGN UP TODAY!
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Communication;
