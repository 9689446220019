import React, { useEffect } from "react";
import NavBar from "./NavBar";
import HeroSection from "./HeroSection";
import KeyMetrics from "./KeyMetrics";
import TruckTypesSection from "./TruckTypesSection";
import ServicesOffered from "./ServicesOffered";
import ChooseUs from "./ChooseUs";
import GettingStarted from "./GettingStarted";
import Footer from "./Footer";
import Testimonials from "./Testimonials";

const HomePage = () => {
  useEffect(() => {
    document.title = "Al Amanah Transport";
  }, []);

  return (
    <div>
      <NavBar />
      <HeroSection />
      <TruckTypesSection />
      <ChooseUs />
      <ServicesOffered />
      <KeyMetrics />
      <GettingStarted />
      <Testimonials />
      <Footer />
      {/* Additional homepage sections go here */}
    </div>
  );
};

export default HomePage;
