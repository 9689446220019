import React from "react";
import { useForm } from "react-hook-form";
import "../styles/DeliverySetup.css"; // Assuming similar styling to CarrierSetup
import Navbar from "../components/NavBar";
import { useState } from "react";
import Footer from "../components/Footer";

const DeliverySetup = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => console.log(data);
  const handleFileChange = (event) => {
    setSelectedFiles([...event.target.files]); // Assuming no server upload for simplicity
  };
  const [selectedFiles, setSelectedFiles] = useState([]);
  const removeFile = (fileName) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName)
    );
  };

  return (
    <div>
      <Navbar />
      <div className="delivery-setup">
        <h1 className="form-title">Delivery Partner Set-Up</h1>
        <p className="form-description">
          Complete the form below to start your journey as a delivery partner.
          Attach any necessary documents at the end of this form.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="companyName">
              Company or Individual Name<span className="required">*</span>
            </label>
            <input
              id="companyName"
              {...register("companyName", {
                required: "This field is required",
              })}
              placeholder="Enter your name or company's name"
              className="form-field"
            />
            {errors.companyName && (
              <p className="error">{errors.companyName.message}</p>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="phone">
              Phone Number<span className="required">*</span>
            </label>
            <input
              type="tel"
              id="phone"
              {...register("phone", { required: "Phone is required" })}
              placeholder="Phone number"
              className="form-field"
            />
            {errors.phone && <p className="error">{errors.phone.message}</p>}
          </div>

          <div className="form-group">
            <label htmlFor="email">
              Email Address<span className="required">*</span>
            </label>
            <input
              type="email"
              id="email"
              {...register("email", { required: "Email is required" })}
              placeholder="Email address"
              className="form-field"
            />
            {errors.email && <p className="error">{errors.email.message}</p>}
          </div>

          <div className="form-group">
            <label htmlFor="deliveryType">
              Type of Deliveries<span className="required">*</span>
            </label>
            <select
              id="deliveryType"
              {...register("deliveryType", {
                required: "Select the type of deliveries you handle",
              })}
              className="form-select"
            >
              <option value="Small Parcels">Small Parcels</option>
              <option value="Large Items">Large Items</option>
              <option value="Perishable Goods">Perishable Goods</option>
              <option value="Fragile Items">Fragile Items</option>
            </select>
          </div>

          <div className="form-group file-upload">
            <label>Upload Documents (Licenses, Insurance, etc.)</label>
            <div className="file-drop-area">
              <button type="button" className="fake-btn">
                Select files
              </button>
              <span className="file-msg">or drop files here</span>
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                className="file-input"
              />
            </div>
            {selectedFiles.map((file, index) => (
              <div key={index} className="file-info">
                <span>{file.name}</span>
                <button type="button" onClick={() => removeFile(file.name)}>
                  Remove
                </button>
              </div>
            ))}
          </div>

          <button type="submit" className="submit-button">
            Submit Application
          </button>
        </form>
      </div>
      <Footer/>
    </div>
  );
};

export default DeliverySetup;
