import React from "react";
import "../styles/KeyMetrics.css";
import { FaTruck } from "react-icons/fa";
import { MdCheckBox } from "react-icons/md";
import { FaDollarSign } from "react-icons/fa";

const KeyMetrics = () => {
  return (
    <div className="key-metrics">
      <div className="header">
        <h2>Our numbers, and what they say.</h2>
      </div>
      <div className="metrics">
        <div className="metric">
          <div className="icon">
            <FaTruck />
          </div>
          <h3>3,200</h3>
          <p>Average miles/week</p>
          <small>
            Our over-the-road drivers cover all 48-states and stay out five-days
            or more.
          </small>
        </div>
        <div className="metric">
          <div className="icon">
            <MdCheckBox />
          </div>
          <h3>$2.43</h3>
          <p>Median dollar per mile</p>
          <small>
            In a seven-day period, we strive to average above two dollars per
            mile for all miles driven, including empty miles. This for DryVan,
            other types of equipment will average higher.
          </small>
        </div>
        <div className="metric">
          <div className="icon">
            <FaDollarSign />
          </div>
          <h3>41,200</h3>
          <p>Weight per load (pounds)</p>
          <small>
            No driver likes heavy loads, but the good paying hauls are usually
            full truckloads. We try to keep the weight of the loads as light as
            possible.
          </small>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", padding: "20px" }}
      >
        {/* <button
          className="cta-button"
          onClick={() => (window.location.href = "/carrier-setup")}
        >
          Drive with Us
        </button> */}
        <button
          className="cta-button"
          onClick={() => (window.location.href = "/delivery-setup")}
        >
          Brokers
        </button>
      </div>
    </div>
  );
};

export default KeyMetrics;
