import React from "react";
import "../styles/About.css";
import heroImage from "../assets/smilingbg1.jpeg"; // Replace with the actual path to the hero image

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";

const About = () => {
  return (
    <div>
      <Navbar />
      <div className="about-section">
        <div className="hero-section">
          <img src={heroImage} alt="" className="hero-image" />
          <div className="hero-text">
            <h1>Welcome to the Al Amanah Family</h1>
            <p>
              At Al Amanah, we're more than just a business - we're a family of
              dispatchers driven by a passion for booking quality loads and
              making our drivers' lives easier.
            </p>
          </div>
        </div>
        {/* Mission Section */}
        <div className="about-content mission-section">
          <h2>Our Mission</h2>
          <p>
            We aim to be the most transparent and driver-focused dispatch
            service in the industry. Our commitment is to ensure our partners
            earn the best rates, build lasting relationships, and find a
            supportive community with us. We're here to shake up the industry
            and bring a fresh approach to trucking.
          </p>
        </div>
        {/* What We Do Section */}
        <div className="about-content services-section">
          <h2>What We Do</h2>
          <p>
            At Al Amanah, we specialize in finding quality loads that match each
            carrier's unique style. With our custom search parameters, we locate
            loads others can't. We ensure you're never booked out without a plan
            to bring you back home. Whether it's sending you to high-load
            regions, booking both departure and return loads, or being available
            24/7, we work alongside you to build a healthy, long-lasting
            relationship. Join our tribe, and let's break bread together.
          </p>
        </div>
        {/* Values Section */}
        <div className="about-content values-section">
          <h2>Our Values</h2>
          <p>
            We believe in transparency, trust, and teamwork. Our drivers are
            more than just partners; they are part of our family. We strive to
            provide the best dispatch services by understanding your needs and
            tailoring our services to fit you perfectly. Let's build a future
            where every driver feels valued and supported.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
