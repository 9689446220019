import React from "react";
import "../styles/RunCreditChecks.css";
import Navbar from "./NavBar";
import Footer from "./Footer";

const RunCreditChecks = () => {
  return (
    <div>
      <Navbar />
      <div className="service-container">
        <div className="service-header">
          <h1>Credit Checks</h1>
        </div>
        <div className="service-description"></div>

        <div className="service-details">
          <h2>Our Credit Check Process</h2>
          <p>
            Our detailed credit check process involves multiple steps to ensure
            the highest level of reliability and accuracy. Here's how we do it:
          </p>
          <div className="service-cards">
            <div className="service-card">
              <h3>Client Verification</h3>
              <p>
                We start by verifying the identity of the client to ensure that
                they are who they claim to be. This step is crucial in
                preventing fraud.
              </p>
            </div>
            <div className="service-card">
              <h3>Financial History</h3>
              <p>
                Our dispatchers review the financial history of the client,
                including past payment behaviors and outstanding debts, to
                assess their creditworthiness.
              </p>
            </div>
            <div className="service-card">
              <h3>Credit Scores</h3>
              <p>
                We check the client's credit scores from reputable credit
                agencies to get a clear picture of their financial stability.
              </p>
            </div>
            <div className="service-card">
              <h3>Risk Assessment</h3>
              <p>
                Based on the gathered data, we perform a thorough risk
                assessment to determine the likelihood of timely payments and
                the overall reliability of the client.
              </p>
            </div>
          </div>
          <button
            className="signup-button"
            onClick={() => (window.location.href = "/carrier-setup")}
          >
            SIGN UP TODAY!
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default RunCreditChecks;
