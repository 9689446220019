import React from "react";
import "../styles/Testimonials.css";
import { FaStar } from "react-icons/fa";

const testimonials = [
  {
    name: "JANET MORRIS",
    title: "A Great Choice!",
    text: "Definitely would recommend Al Amanah for semi-truck dispatching! I had Al Amanah recommended to me by a friend and I’m glad I gave them a call.",
  },
  {
    name: "WILLIE BROWN",
    title: "Always Ready to Roll!",
    text: "This is the best trucking dispatch company out there! Ramil and his team did an amazing job with dispatching high-paying freight!! I drive on weekdays and I always have loads ready for me Monday!",
  },
  {
    name: "SARAH RAHMAN",
    title: "Top-Notch Dispatching Services!",
    text: "Al Amanah are the best truck dispatchers I’ve worked with in the last 5 years. Their dispatching services are top-notch, offering the best loads on a consistent basis. Very nice and easy to work with. Wonderful freight dispatch service. Would recommend them.",
  },
];

const Testimonial = () => {
  return (
    <div className="testimonial-section">
      <div className="testimonial-header">
        <p className="testimonial-subtitle">TESTIMONIALS</p>
        <h2 className="testimonial-title">WHAT OUR DRIVERS SAY</h2>
        <p className="testimonial-description">
          See what our satisfied drivers have to say about our dispatch services
          and how we help them achieve success on the road.
        </p>
      </div>
      <div className="testimonial-cards">
        {testimonials.map((testimonial, index) => (
          <div className="testimonial-card" key={index}>
            <h3 className="testimonial-name">{testimonial.name}</h3>
            <div className="testimonial-stars">
              {[...Array(5)].map((star, i) => (
                <FaStar key={i} className="star-icon" />
              ))}
            </div>
            <p className="testimonial-title">{testimonial.title}</p>
            <p className="testimonial-quote">“</p>
            <p className="testimonial-text">{testimonial.text}</p>
          </div>
        ))}
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", padding: "20px" }}
      >
        <button
          className="cta-button"
          onClick={() => (window.location.href = "/delivery-setup")}
        >
          Brokers
        </button>
      </div>
    </div>
  );
};

export default Testimonial;
