import React from "react";

class Contact extends React.Component {
  render() {
    return (
      <div>
        <h1>Contact Us</h1>
        <p>Email: example@example.com</p>
        <p>Phone: 123-456-7890</p>
      </div>
    );
  }
}

export default Contact;
